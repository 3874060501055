import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Alert, Button, Stack, TextField } from "@mui/material";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountryAction } from "../../redux/reducers/meta/getAllCountry";
import Table from "../../components/Table";
import { getMetaDataAction } from "../../redux/reducers/meta/getMetaData";
import { addCountryPricingAction } from "../../redux/reducers/meta/addCountry";
import { Toast } from "../../components/Toast";
import { changeLimit, changePage } from "../../redux/table/pagination";
import Loader from "../../components/Loader";
import { BigLoader } from "../../components/BigLoader";
import { useNavigate } from "react-router-dom";

const CountryCurrency = () => {
  
  const [file, setFile] = useState(null);
  const [arr, setArr] = useState([]);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [errorText, setErrorText] = useState(null);
  const [snake, setSnake] = useState(false);
  const dispatch = useDispatch();
  const {data:countryData, loading:countryLoading } = useSelector((state) => state.allCountry);
  const { message, status, loading } = useSelector((state) => state.addCountryPricing);
  const {page,limit} = useSelector(state=>state.tablePagination)
  const navigate = useNavigate();
  useEffect(() => {
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: function (results) {
          setData(results.data);
          aligningData(results.data);
          console.log(results.data);
        },
      });
    }
  }, [file]);

  const aligningData = (data=[]) => {
    if (data.length > 0) {
      data.map((item) => {
        if(arr.includes(item["country"])) {
          return
        }
       setArr((prev) => [
         ...prev,
         {
          country: item["country"],
          currencySymbol: item["currencySymbol"],
          priceInfo: [
            {
              planId: item["planId"],
              duration: Number(item["duration"]),
              amount: Number(item["amount"]),
              discountPercentage: Number(item["discountPercentage"]),
              finalAmount: Number(item["finalAmount"]),
              description: item["description"],
            },
          ],
        }
       ])
      });
    }
  };
 
 

  useEffect(() => {
    dispatch(getAllCountryAction());
    dispatch(changeLimit(25))
    dispatch(changePage(1))
    
  }, []);

console.log(arr)

  const handleSubmit = async () => {
    try {

      if (arr.length === 0) {
        return;
      }
      let data = {
        countryPricing: arr,
      }
      await dispatch(addCountryPricingAction(data))
      await dispatch(getAllCountryAction());
      setSnake(true);
      setFile(null);
    } catch (error) {
      setSnake(false);
      console.log(error.message);
    }
  }
  const column =[
    {
        headerName: "Country ID",
        property: "_id",
      },
      {
        headerName: "Country",
        property: "country",
      },
      {
        headerName: "Currency Symbol",
        property: "currencySymbol",
      },
      ]
  return (
    <>
  
    <div className="container-2">
      <Header selectedNumber={1}></Header>
      <button className="btn-submit btn-back" onClick={() => navigate(-1)} >Back</button>
      <Stack
        sx={{
          flexDirection: "column",
          gap: 2,
          my: 2,
        }}
      >
        {error && <Alert severity="error">{errorText}</Alert>}
        <label htmlFor="country">Upload Price List Country Wise</label>
        <TextField
          variant="outlined"
          InputProps={{
            inputProps: {
              accept: ".csv",
            },
          }}
          onChange={(e) => setFile(e.target.files[0])}
          type="file"
        />
      <Button sx={{width:"20%"}} disabled={loading} variant="contained" onClick ={handleSubmit}> Submit</Button>
      </Stack>
      <h3>Subscription Price List Country Wise</h3>
      <Table data={countryData?.slice((page-1)*limit,page*limit)} totalPages={Math.ceil(countryData?.length/limit)} column={column} redirectTo ="/edit/country/currency"/>
    </div>
    <Toast open={snake} message={message} setOpen={setSnake} status={status}/>
    {countryLoading && <BigLoader/>}
    </>
  );
};

export default CountryCurrency;
